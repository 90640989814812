.landing-page-wrapper {
    display: flex;

    #content {
        max-width: calc(100% - 258px);
        width: calc(100% - 258px);
        padding: 38px 40px;

        h2 { 
            font-size: 24px;
            font-weight: 700; 
        }

        h3 {
            font-size: 20px;
            font-weight: 600; 
        }

        h2,h3 {
            margin-bottom: 24px;
            color:  #181818;
            line-height: 120%;
            margin-top: 0;
        }

        section {
            margin-bottom: 80px;
            width: 100%;
        }

        #sentiment-section {
            display: flex;
            gap: 32px;

            #overall {
                width: 25%;
            }

            #overtime {
                width: 75%;
            }
        }

        #recommendations-section {
            display: flex;
            gap: 24px;
            flex-direction: row;
            align-items: center;

            #tweet-impact {
                width: 20%;
            }

            #countemeasures {
                width: 80%;
            }
        }

        #country-profiles-wrapper {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: baseline;

            a {
                color: white;
                text-decoration: none;

                &:hover {
                    color: white;
                    text-decoration: none;
                }
            }
        }
    }
    
}

@media only screen and (max-width: 1300px) {
    .landing-page-wrapper {
    
        #content {
    
            #sentiment-section {
                display: flex;
                flex-direction: column;
                gap: 32px;
    
                #overall {
                    width: unset;

                    #overall-sentiment-chart {
                        display: flex;
                        justify-content: center;
                    }
                }
    
                #overtime {
                    width: 100%;
                }
            }
    
        }
        
    }
}

@media only screen and (max-width: 800px) {
    .landing-page-wrapper {
    
        #content {
    
            #recommendations-section {
                display: flex;
                gap: 24px;
                flex-direction: column;    
            }
        }
        
    }
}