#node-graph-container {

    h3 {
        color: #181818;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%;
        margin-block-start: 0;
        margin-block-end: 8px;
    }

    .content-container {

        display: flex;
        gap: 16px;

        .node-graph-wrapper {
            width: 100%;
            height: 486px;
            border-radius: 6px;
            box-sizing: border-box;
        
            #layout-buttons {
                display: flex;
                gap: 8px;
                display: none;
            }
        
        }

    }

    a {
        visibility: hidden;
    }

}