.circular-node {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    background-color: #EFF2F4;
    border: 0.5px solid #A1A1A1;
    text-align: center;
    padding: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    white-space: pre-line;

    &.bot-node {
      border-width: 3px;
      font-weight: 600;
    }
  }

  // .bot-node .circular-node {
  //   border: 1px solid red;
  //   background-color: #E7B98F;
  // }

  // .non-bot-node .circular-node {
  //   background-color: #B0D99D;
  //   border: 1px solid green;
  // }

  .initial-node .circular-node{
    background-color: #00556F;
    color: white;
  }

  .first-tweet .circular-node{
    background-color: #4CA98E;
  }

  .initial-node.first-tweet .circular-node {
    background-color: #00556F;
    color: white;
  }

  .tooltip-node-content {
    color: black;
    padding: 16px;
    border-radius: 4px;
    border: 1px solid #00556F;
    background: #FFF;
    box-shadow: 1px 2px 6px 0px rgba(0, 0, 0, 0.25);
    width: 353px;
    display: flex;
    flex-direction: column;
    gap: 12px;

    p {
      color: #181818;
      font-size: 16px;
      font-style: normal;
      font-weight: 300;
      line-height: 120%;
      margin: 0;      

      span {
        font-weight: 600;
      }

    }

    .sentiment {
      display: flex;
      align-items: baseline;

      p.title {
        position: relative;
        top: -12px;

        &.no-sentiment {
          top: 0;
        }
        
      }

      .speedometer {
        display: flex;
        align-items: center;
        gap: 8px;

        img {
          height: 38px;
        }

        span {
          font-size: 16px;
          font-weight: 600;
          text-transform: capitalize;
        }
      }

    }

  }
  