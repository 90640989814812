#sidebar {
    height: 100vh;
    width: 258px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: sticky;
    top: 0;
    box-shadow: 1px 0px 10px 0px rgba(0, 0, 0, 0.13);

    .top-section {
        padding: 24px 40px 0;
        display: flex;
        flex-direction: column;
        align-items: center;

        h2 {
            color:  #181818;
            text-transform: uppercase;
            font-family: Mister London Sans;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%;
            margin: 0;
        }
    
        button {
            width: 100%;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 120%;
            margin: 24px 0;

            a {
                color: white;
                text-decoration: none;
            }
    
            #icon {
                color: rgba(255, 255, 255, 1);
            }
        }

        #search {
            display: flex;
            padding: 10px 16px;
            align-items: center;
            gap: 8px;
            border-radius: 100px;
            background: #EFF2F4;

            input {
                border: none;
                outline: none;
                background: #EFF2F4;
                width: 100%;
                color:  #181818;
                font-size: 16px;
                font-style: normal;
                font-weight: 200;
                line-height: 120%;

                &:hover,&:focus {
                    border: none;
                    outline: none;
                }
            }
        }

        #filters {
            color: #181818;
            font-size: 14px;
            line-height: 130%;
            align-self: flex-start;
            margin: 12px 0 16px;

            .filter {
                span {
                    font-weight: 300;
                }

                select {
                    border: none;
                    outline: none;
                    font-weight: 500;
                    margin-bottom: 8px;

                    &:focus-visible,
                    &:focus,&:active {
                        border: none;
                        outline: none;
                    }

                    &::-ms-expand {
                        display: none;
                      }
                }
            }
        }
    }

    ul {
        width: 100%;
        padding-inline-start: 0;
        list-style-type: none;
        display: flex;
        flex-direction: column;
        gap: 8px;
        overflow-y: scroll;
        margin-inline-start: 0;
        margin-block-start: 0;
        margin-block-end: 0;

        li {
            padding: 8px 32px;
            border-right: 8px solid transparent;
            color:  #181818;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 130%;
            cursor: pointer;

            .labels {
                color: #6E6E6E;
                font-size: 12px;
                line-height: 120%;
                text-transform: uppercase;
            }

            div {
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;  
                overflow: hidden;
            }

            &.active,&:hover {
                background-color: #EFF2F4;
            }

            &:hover {
                text-decoration: underline;
            }

            &.active {
                border-color:  #00556F;
            }
        }

        &::-webkit-scrollbar {
            width: 6px; /* Width of the scrollbar */
        }
        
        &::-webkit-scrollbar-thumb {
        background-color: #A1A1A1; /* Color of the scrollbar thumb */
        border-radius: 6px;
        }
        
        &::-webkit-scrollbar-track {
        background-color: #DADADA; /* Color of the scrollbar track */
        border-radius: 8px;
        }

        
    }
}