#country-profiles {
    margin: auto;
    max-width: 50%;
    padding: 24px 0 ;
    color: #181818;

    sup {
        font-size: 10px;
    }

    h1 {
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
        margin-bottom: 24px;
    }

    #country-links {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 24px;
        margin-bottom: 24px;
        
        a {
            text-decoration: none;
            font-size: 16px;
            font-weight: 600;
            color: #181818;

            &:hover {
                color: #002F3D;
                text-decoration: underline;
            }

        }

        svg {
            color: #002F3D;
            font-size: 10px;
        }
    }

    h3,h4 {
        font-weight: 600;
        margin-bottom: 24px;
        line-height: 120%;
        margin-top: 0;
    }

    h3 {
        font-size: 20px;

        &.section-title {
            color: #024c63;
            margin-top: 40px;
        }
    }

    h4 {
        font-size: 16px;
    }

    p {
        font-style: normal;
        font-weight: 400;
    }

    ul {

        li {
            padding: 6px 0;
            list-style-type: square;
            &:has(ul) {
              list-style-type: disclosure-closed;  
            }
            

            ul {

                li {
                    list-style-type: square;
                }

            }
        }

    }


}

a.footnote-link {
    color: #00556F;
}