.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-family: 'Roboto' !important;
}

.App .page-container {
  max-width: 1365px;
  width: 100%;
  margin: auto;
}

.App .mister {
  font-family: 'Mister London Sans' !important;
}

@font-face {
  font-family: "Roboto";
  src: local("Roboto-Regular"),
  url("./fonts/Roboto/Roboto-Regular.ttf") format("truetype")
}

@font-face {
  font-family: "Mister London Sans";
  src: local("Mister London Sans"),
  url("./fonts/MisterSans/Mister\ London\ Sans.otf") format("opentype")
}

@font-face {
  font-family: "Mister London Script";
  src: local("Mister London Script"),
  url("./fonts/MisterSans/Mister\ London\ Script.otf") format("opentype")
}

@font-face {
  font-family: "Mister London Sans Regular";
  src: local("Mister London Sans Regular"),
  url("./fonts/MisterSans/mister-london-sans-regular.otf") format("opentype")
}
