.privacy-policy {
    max-width: 50%;
    margin: auto;
    padding: 24px;

    .section {
        margin-bottom: 16px;
        display: flex;
        flex-direction: column;
    }

    .blue, h4 {
        color: #024c63;
    }

    .center {
        text-align: center;
    }

    .underlined {
        text-decoration: underline;
    }

    p {
        margin-block-end: 8px;
        margin-block-start: 8px;
    }

    h1 {
        font-size: 24px;
    }

    h3 {
        font-size: 20px;
    }

    h4 {
        font-size: 18px;
        margin-block-start: 8px;
        margin-block-end: 8px;
    }

    ul {
        li {
            list-style-type: square;
        }
    }

}