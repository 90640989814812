.recommendations-wrapper {

    .countermeasures {

        p.title {
            color:  #181818;
            font-size: 16px;
            font-weight: 500;
            line-height: 120%;
            margin: 0;
            margin-bottom: 16px;
        }

        .accordion {

            .acc-title {
                text-transform: capitalize;
            }
            
        }

    }

}