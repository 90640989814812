#map-information {

    .regions {
        display: flex;
        flex-direction: column;
        gap: 24px;
    }

    p.title {
        margin: 0;
        margin-bottom: 20px;
        color:  #181818;
        font-size: 16px;
        font-weight: 400;
        line-height: 120%; 

        span {
            font-weight: 600;
        }
    }

    table {
        width: 100%;
        padding-inline: 8px;
        border-collapse: collapse;

        tr {
            color:  #181818;
            font-size: 16px;
            line-height: 120%;

            th {
                text-align: left;
                font-weight: 600;
                letter-spacing: -0.24px;
                padding: 0 4px 12px 0;
            }

            td {
                font-weight: 400;
                border-bottom: 0.2px solid #BFBFBF;
                padding: 8px 4px 4px 4px;
                text-transform: capitalize;

                .table-content {
                    display: flex;
                    align-items: center;
                }

                // .table-icon {
                //     width: min-content; 
                // }
            }

            td:nth-child(1) {
                width: 60%;
            }

            td:nth-child(2) {
                width: 40%;
            }
        }

    }

}