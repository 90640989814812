footer {
    background-color: #002F3D;
    padding: 40px 0px;
    margin-top: auto;

    #footer-wrapper {
        display: flex;
        gap: 18px;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        color: #FFF;
        font-size: 16px;
        line-height: 120%;
        padding-inline: 20px;

        .visit-section {
            display: flex;
            font-size: 20px;

            span {
                font-weight: 400;
                margin-right: 8px;
            }

            .footer-link {
                font-weight: 600;
            }
        }

        #eu-info {

            display: flex;
            justify-content: center;
            align-items: center;
            gap: 16px;
            font-weight: 300;

            img {
                max-height: 40px;
            }

        }

        #links {

            display: flex;
            gap: 20px;

            .footer-link {
                font-weight: 400;
                text-decoration: none;
                color: #FFF;

                &:hover {
                    text-decoration: underline;
                }
            }

        }

        .footer-link {
            font-weight: 500;
            color: #FFF;

            &:hover {
                text-decoration: underline;
            }
        }

        
    }
}