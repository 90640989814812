#selected-investigation {
    padding: 24px 32px;
    border-radius: 12px;
    background: #EFF2F4;
    box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.15);

    #information {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        gap: 0;

        .items-wrapper {
            display: flex;
            flex-direction: column;
            gap: 24px;

            .item {
                color:  #181818;
                font-style: normal;
                font-weight: 400;
                line-height: 120%; 
                display: flex;
                flex-direction: column;
                gap: 8px;
                span {
                    font-size: 14px;
                    letter-spacing: 0.28px;
                    text-transform: uppercase;
                }
    
                p {
                    margin: 0;
                    font-size: 20px;      
                }

                .info-with-action {
                    display: flex;

                    button {
                        outline: none;
                        border: none;
                        background-color: transparent;
                        color: rgba(0, 0, 0, 1);
                        width: 16px;
                        height: 16px;
                        font-weight: 400;
                        margin-top: -2px;
                        cursor: pointer;
                    }
                }
            }
        }
  
    }

    #actions {
        margin-top: 32px;
        display: flex;
        justify-content: flex-end;
        gap: 12px;
    }
}

#delete-actions {
    margin-top: 32px;
    display: flex;
    justify-content: flex-end;
    gap: 12px;
}

@media only screen and (max-width: 800px) {

    #selected-investigation {
    
        #information {
            flex-direction: column;
            gap: 24px;
      
        }
}

}