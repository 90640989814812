#overall-sentiment-score-wrapper {


    p {
        margin: 0;
        margin-bottom: 8px;
        color: #181818;
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: 120%;  

        span {
            font-weight: 600;
        }
    }

    #sentiment-legend {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 16px;
        margin-top: 24px;

        .legend-item {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 4px;
            color: #181818;
            font-size: 16px;
            line-height: 120%;

            .percentage {
                padding: 10px 8px;
                border-radius: 4px;
                font-weight: 400;
                width: min-content;
                cursor: pointer;
                line-height: 100%;
                text-align: center;

                &.positive {
                    background-color: #91CC75;
                }

                &.negative {
                    background-color: #E66;
                }

                &.neutral {
                    background-color: #B5B5B5;
                }
            }

            span {
                font-weight: 400;
            }
        }
    }
}