$navigationBarBackgroundColor: #002F3D;

#nav-bar-wrapper {
    background-color: $navigationBarBackgroundColor;
    z-index: 1030;
    width: 100%;
    
    .nav-bar-container {
        display: flex;
        padding: 24px 16px;
        align-items: center;
        width: 100%;
        justify-content: space-between;
        box-sizing: border-box;
    }

    a {
        cursor: pointer;
        color: white;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }

    #nav-main-part {

        #logo {
            max-height: 50px;
        }
    
    }

    #secondary-menu {
        align-self:center;
        color: white;
        font-size: 18px;
        font-weight: 400;
        display: flex;
        align-items: center;
        gap: 16px;

        a {
            display: flex;
            gap: 6px;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        }

        #user-icon {
            width: 32px;
            height: 32px;
        }
    }
}

#manual-link{
    color: #002F3D;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}