#manual-wrapper {
    max-width: 50%;
    margin: auto;
    padding: 24px;

    .section {
        margin-bottom: 16px;
        display: flex;
        flex-direction: column;
    }

    h3,h4 {
        color: #024c63;
    }

    p {
        margin-block-end: 8px;
        margin-block-start: 8px;
    }

    h1 {
        font-size: 24px;
    }

    h3 {
        font-size: 20px;
    }

    h4 {
        font-size: 18px;
        margin-block-start: 8px;
        margin-block-end: 8px;
    }

    ul {
        li {
            list-style-type: square;
        }
    }

    .custom-image {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        font-weight: 600;
        color: #024c63;
        padding: 16px 0;

        img {
            width: 100%;
        }

        span {
            padding: 8px 0;
        }
    }
}