.colors-table {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    padding: 10px 0;

    &.centered {
        justify-content: center;
    }

    .color-item {
        display: flex;
        align-items: center;
        gap: 8px;
        
        .color {
            width: 26px;
            height: 14px;
            border-radius: 4px;

            &.square {
                width: 14px;
            }
        }
    }

    span {
        font-size: 14px;
        font-weight: 400;
    }

}