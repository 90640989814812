  .default-countries {
    fill: #313131;

    &.highlighted-country {
      fill: #4CA98E;
      cursor: pointer;

      &.green {
        fill: #86CB67;

        &:hover {
          fill:#4CA98E;
        }
      }
  
      &.yellow {
        fill: #F9C858;
      }
  
      &.red {
        fill: #E66;
      }

      &.selected-country {
        fill:#91CC75;
      }

      &:hover {
        fill:#91CC75;
      }
      
    }

  }

  .map-container {
    background: #F4F4F4;
    position: relative;

    #zoom-buttons {
      padding: 5px 0;
      position: absolute;
      right: 0;

      button {
        padding: 0;
        min-width: 40px
      }

    }

    #countries-list {
      position: absolute;
      left: 0;
      color: #181818;
      font-size: 16px;
      line-height: 120%;
      background-color: #F4F4F4;
      height: 100%;
      max-width: 25%;
      

      .wrapper {
        margin: 16px 0 0 16px;
        // border-right: 0.8px solid #181818;
        height: 95%;
        padding-right: 16px;
      }


      div {
        font-weight: 700;
      }

      ul {
        padding-inline-start: 0; 
        list-style-type: none;
        overflow-y: scroll;
        max-height: 90%;
        padding-right: 24px;

        hr {
          margin-block-start: 0;
        }

        li {
          font-weight: 400;
          display: flex;
          gap: 8px;
          align-items: flex-end;

          &:not(.non-clickable) {
            cursor: pointer;
          }

          &:not(.non-clickable):hover {
            text-decoration: underline;
            font-weight: 600;
          }

          &.non-clickable:not(:last-child) {
            margin-bottom: 4px;
          }

          &:not(:last-child) {
            margin-bottom: 8px;
          }
        }

        &::-webkit-scrollbar {
            width: 6px; /* Width of the scrollbar */
        }
        
        &::-webkit-scrollbar-thumb {
          background-color: #A1A1A1; /* Color of the scrollbar thumb */
          border-radius: 6px;
        }
        
        &::-webkit-scrollbar-track {
          background-color: #DADADA; /* Color of the scrollbar track */
          border-radius: 8px;
        }
      }
    }
  }
  