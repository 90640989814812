.custom-select {
    position: relative;
    // width: fit-content

    .select-header {
        padding: 4px 0;
        color: #181818;
        cursor: pointer;
        width: fit-content;
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 600;
        gap: 4px;
        background: white;
    
        span {
            font-weight: 300;
        }
    
      }

      .options-container {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        background-color: white;
        box-shadow: 7px 4px 24px -12px rgba(178,165,165,0.9);
        z-index: 20;
    
        .option {
            padding: 10px;
            cursor: pointer;
        }
    
        .option:hover {
            background-color: #ddd;
        }
      }
  }
  
  
  
  
  
  
  
  
  
  