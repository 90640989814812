#new-investigation-wrapper {
    padding-bottom: 20px;

    form {
        margin: auto;
        max-width: 50%;
        display: flex;
        flex-direction: column;
        gap: 32px;
        padding: 24px;

        h1 {
            color: #181818;
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
            margin-bottom: 24px;
        }

        .form-buttons {
            display: flex;
            gap: 16px;
            align-self: flex-end;
            margin-top: 24px;

            #whiteButton {
                background-color: white;
                border: 2px solid  #00556F;

                a {
                    color: #181818;
                    text-decoration: none;
                }
            }
        }

        #severity-likelihood-section {
            display: flex;
            gap: 24px;
            align-items: center;
        }
        
    }
}