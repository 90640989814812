.tweet-impact-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
        height: 105px;
    }

    span.title {
        color:  #181818;
        font-size: 16px;
        font-weight: 400;
        line-height: 120%;
            }
}